import { config } from "./config.js";

const $ = window.$;

const toastr = require("toastr");
toastr.options.fadeOut = 7500;
window.toastr = toastr;


// const fetchFirebaseToken = async function (accessToken) {
//   console.log(
//     `Using access token: ${accessToken} to request firebase custom authentication token (CAT)`,
//   );
//   const catRes = await fetch("/signin/cat", {
//     method: "POST",
//     headers: {
//       Authorization: `Bearer: ${accessToken}`,
//     },
//   });
//   try {
//     const catData = await catRes.json();
//     console.log(catData);
//     if (catData.status) {
//       //pass 🐱 to calling service
//       const service = window.localStorage.service;
//       delete window.localStorage.service;
//       const redirectUrl = `https://${service}/signin?fastboot=false#token=${catData.cat}`;
//       console.log(redirectUrl);
//       window.location.href = redirectUrl;
//     } else {
//       //We failed to fetch a 🐱
//       toastr.error(catData.error, "Error fetching 🐱 cat");
//     }
//   } catch (error) {
//     console.error(error);
//     toastr.error("Unable to fetch 🐈", "Network error fetching 🐈 cat");
//   }
// };
import { OAuth2Client, generateCodeVerifier } from '@badgateway/oauth2-client';

const init = async () => {
  console.log(`I am running on version: ${config("version")}`);

  console.table(config());

  const serviceConfigResponse = await window.fetch("/serviceConfig");
  const serviceConfig = await serviceConfigResponse.json();
  console.table(serviceConfig);

  $("title").text(`${serviceConfig.serviceName} (Authentik)`);

  if (!window.localStorage.service) {
    let service = "";
    const domain = "badgetracker.app";
    const serviceSplit = window.location.hash.split("service=");
    if (serviceSplit.length > 1) {
      service = serviceSplit[1].split("&")[0];
    }
    if (service) {
      console.log(`Detected service: ${service}`);
      if (
        service.replace(/[^0-9-a-z.]/gi, "").endsWith(`.${domain}`) ||
        service === domain
      ) {
        console.log("service is valid");
        window.localStorage.service = service;
      } else {
        if (service === "localhost") {
          console.log("Localhost, treating as valid service");
          window.localStorage.service = service;
          return;
        }
        console.log(`Invalid domain for service: ${service}`);
        window.localStorage.service = serviceConfig.defaultService;
      }
    } else {
      console.log("No service detected, defaulting url.");
      window.localStorage.service = serviceConfig.defaultService;
    }
  }

  window.OAuth2Client = OAuth2Client;
  console.log(`I should redirect to Authentik for sign in`);

  window.client = new OAuth2Client({
    clientId: "QwdrVVISbGYAPpzxoZLjFwsn1ROCufNz5AQwPALz",
    server: "https://authentik.badgetracker.app/application/o/authcore",
    tokenEndpoint: 'https://authentik.badgetracker.app/application/o/token/',
    authorizationEndpoint: 'https://authentik.badgetracker.app/application/o/authorize/',
    //discoveryEndpoint: "https://authentik.badgetracker.app/application/o/authcore/.well-known/openid-configuration"
  });


  const searchParams = new URLSearchParams(location.search);
  if (searchParams.get('code')) {
    try {
    console.log("We have a code, attempting to get token");
    const oauth2Token = await client.authorizationCode.getTokenFromCodeRedirect(
      document.location,
      {
        /**
         * The redirect URI is not actually used for any redirects, but MUST be the
         * same as what you passed earlier to "authorizationCode"
         */
        redirectUri: `https://${window.location.host}/signin`,

        /**
         * This is optional, but if it's passed then it also MUST be the same as
         * what you passed in the first step.
         *
         * If set, it will verify that the server sent the exact same state back.
         */
        state: 'some-string',

        //codeVerifier: localStorage.codeVerifier,

      }
    );
    console.log(oauth2Token);


    const cat_result = await fetch('https://europe-west2-badgetrackerapp.cloudfunctions.net/legacy_token_exchange', {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
            jwt: await oauth2Token.accessToken
        })
    }).then(function (res) {
        return res.json();
    });
    console.log(cat_result);
    if (cat_result.cat) {
        console.log("We have a CAT, proceeding to sign in");
        if (!window.localStorage.service) {
          window.localStorage.service = "badgetracker.app";
        }
        const application_url = `https://${window.localStorage.service}/signin?fastboot=false#token=${cat_result.cat}`
        delete(window.localStorage.service);
        location = application_url;
    } else {
      console.error(`Failed to fetch CAT: ${cat_result}`);
    }
    return;
    } catch (err) {
      console.error(err);
    }
  }

  console.log("Generating authorize URL");
  localStorage.codeVerifier = await generateCodeVerifier();
  const authorizationUrl = await client.authorizationCode.getAuthorizeUri({

    // URL in the app that the user should get redirected to after authenticating
    redirectUri: `https://${window.location.host}/signin`,

    // Optional string that can be sent along to the auth server. This value will
    // be sent along with the redirect back to the app verbatim.
    state: 'some-string',

    //codeVerifier: localStorage.codeVerifier,

    scope: ['email', 'openid', 'profile'],

  });
  console.log(authorizationUrl);
  window.authorizationUrl = authorizationUrl;
  location = authorizationUrl;
};

init();
